.faqs h1 {
	display: block;
	font-size: 2em;
	font-weight: bold;
	margin-top: 8px;
  margin-bottom: 8px;
}

.faqs h2 {
	display: block;
	font-size: 1.5em;
	font-weight: bold;
	margin-top: 8px;
  margin-bottom: 8px;
}

.faqs h3 {
	display: block;
	font-size: 1.17em;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;
	margin-top: 8px;
  margin-bottom: 8px;
}

.faqs a {
	color: blue;
	text-decoration: underline;
}

.faqs a:visited {
	color: purple;
}

.faqs a:hover {
	cursor: pointer;
}

.faqs a:active {
	color: red;
}

.faqs ul,
.faqs ol {
	display: block;
	padding: 0 0 0 40px;
}

.faqs ul {
	list-style-type: disc;
	list-style-position: inside;
}

.faqs ol {
	list-style-type: decimal;
	list-style-position: inside;
}